import { ArcRotateCamera, FxaaPostProcess, Vector3 } from '@babylonjs/core'

const createCamera = (scene) => {
  const camera = new ArcRotateCamera(
    'camera',
    0,
    0,
    0,
    new Vector3(0, 0, 0),
    scene,
  )

  camera.panningSensibility = 0
  camera.lowerBetaLimit = Math.PI / 2 - Math.PI / 2 / 4
  camera.upperBetaLimit = Math.PI / 2 + Math.PI / 2 / 4
  // eslint-disable-next-line no-new -- new is required
  new FxaaPostProcess('fxaa', 1.4, camera)

  return camera
}

export default createCamera
