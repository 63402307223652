// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SurfaceColorRow } from '../typings';

const data = [
  {
    "id": "black",
    "name": "Black",
    "props": {
      "hex": "#323236"
    }
  },
  {
    "id": "graphite",
    "name": "Graphite",
    "props": {
      "hex": "#404041"
    }
  },
  {
    "id": "rosewood",
    "name": "Rosewood",
    "props": {
      "hex": "#7d5045"
    }
  },
  {
    "id": "hotPink",
    "name": "Hot Pink",
    "props": {
      "hex": "#ec008c"
    }
  },
  {
    "id": "white",
    "name": "White",
    "props": {
      "hex": "#faf3f2"
    }
  },
  {
    "id": "navy",
    "name": "Navy",
    "props": {
      "hex": "#0f1e4a"
    }
  },
  {
    "id": "hickory",
    "name": "Hickory",
    "props": {
      "hex": "#cb3029"
    }
  },
  {
    "id": "honey",
    "name": "Honey",
    "props": {
      "hex": "#f9b53f"
    }
  },
  {
    "id": "pink",
    "name": "Pink",
    "props": {
      "hex": "#a5495f"
    }
  },
  {
    "id": "darkNavy",
    "name": "Dark Navy",
    "props": {
      "hex": "#151033"
    }
  },
  {
    "id": "lightOrange",
    "name": "Light Orange",
    "props": {
      "hex": "#f07e00"
    }
  },
  {
    "id": "babyBlue",
    "name": "Baby Blue",
    "props": {
      "hex": "#79b9e0"
    }
  },
  {
    "id": "orange",
    "name": "Orange",
    "props": {
      "hex": "#f13104"
    }
  },
  {
    "id": "scarlet",
    "name": "Scarlet",
    "props": {
      "hex": "#ba000b"
    }
  },
  {
    "id": "purple",
    "name": "Purple",
    "props": {
      "hex": "#3b235c"
    }
  },
  {
    "id": "metallicGold",
    "name": "Metallic Gold",
    "props": {
      "hex": "#c8954b"
    }
  },
  {
    "id": "athleticGold",
    "name": "Athletic Gold",
    "props": {
      "hex": "#ddb96b"
    }
  },
  {
    "id": "darkGreen",
    "name": "Dark Green",
    "props": {
      "hex": "#013028"
    }
  },
  {
    "id": "lightBrown",
    "name": "Light Brown",
    "props": {
      "hex": "#956a29"
    }
  },
  {
    "id": "wine",
    "name": "Wine",
    "props": {
      "hex": "#523234"
    }
  },
  {
    "id": "royal",
    "name": "Royal",
    "props": {
      "hex": "#005ba1"
    }
  },
  {
    "id": "columbiaBlue",
    "name": "Columbia Blue",
    "props": {
      "hex": "#71a4d6"
    }
  },
  {
    "id": "teal",
    "name": "Teal",
    "props": {
      "hex": "#277e87"
    }
  },
  {
    "id": "limeGreen",
    "name": "Lime Green",
    "props": {
      "hex": "#bfff00"
    }
  },
  {
    "id": "coolGray",
    "name": "Cool Gray",
    "props": {
      "hex": "#9CA09E"
    }
  },
  {
    "id": "gray",
    "name": "Gray",
    "props": {
      "hex": "#5a5146"
    }
  },
  {
    "id": "sky",
    "name": "Sky",
    "props": {
      "hex": "#8fc8d4"
    }
  },
  {
    "id": "cloud",
    "name": "Cloud",
    "props": {
      "hex": "#e5e1d0"
    }
  },
  {
    "id": "seafoam",
    "name": "Seafoam",
    "props": {
      "hex": "#ccdeb9"
    }
  },
  {
    "id": "mint",
    "name": "Mint",
    "props": {
      "hex": "#7aa87a"
    }
  },
  {
    "id": "militaryGreen",
    "name": "Military Green",
    "props": {
      "hex": "#353500"
    }
  },
  {
    "id": "softwareGray",
    "name": "Software Gray",
    "props": {
      "hex": "#716e6f"
    }
  },
  {
    "id": "lavender",
    "name": "Lavender",
    "props": {
      "hex": "#d7bfe3"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SurfaceColorRow[]>> = data;

export type SurfaceColor = typeof typed[number];

export const SURFACE_COLOR_INDEX_KEY = "id";
export type SurfaceColorIndexKey = "id";
export type SurfaceColorId = SurfaceColor["id"];

let i = 0;
export const SURFACE_COLOR_DICT = {
  "black": typed[i++],
  "graphite": typed[i++],
  "rosewood": typed[i++],
  "hotPink": typed[i++],
  "white": typed[i++],
  "navy": typed[i++],
  "hickory": typed[i++],
  "honey": typed[i++],
  "pink": typed[i++],
  "darkNavy": typed[i++],
  "lightOrange": typed[i++],
  "babyBlue": typed[i++],
  "orange": typed[i++],
  "scarlet": typed[i++],
  "purple": typed[i++],
  "metallicGold": typed[i++],
  "athleticGold": typed[i++],
  "darkGreen": typed[i++],
  "lightBrown": typed[i++],
  "wine": typed[i++],
  "royal": typed[i++],
  "columbiaBlue": typed[i++],
  "teal": typed[i++],
  "limeGreen": typed[i++],
  "coolGray": typed[i++],
  "gray": typed[i++],
  "sky": typed[i++],
  "cloud": typed[i++],
  "seafoam": typed[i++],
  "mint": typed[i++],
  "militaryGreen": typed[i++],
  "softwareGray": typed[i++],
  "lavender": typed[i++]
} as const;

export { typed as SURFACE_COLOR };
