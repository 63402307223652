const defaultViewAngleId = 'overview'

const viewAngles = {
  overview: {
    label: 'Overview',
    exposeForUI: true,
  },
  barrel: {
    label: 'Barrel',
    exposeForUI: true,
  },
  handle: {
    label: 'Handle',
    exposeForUI: true,
  },
  end: {
    label: 'End',
    exposeForUI: true,
  },
  knob: {
    label: 'Knob',
    exposeForUI: true,
  },
  front: {
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      width: 1200, // dublicate value in Finalize.css ".finalize-preview img"
      height: 630,
    },
  },
}

export default viewAngles
export { defaultViewAngleId }
