// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { OvalLogoColorRow } from '../typings';

const data = [
  {
    "id": "black",
    "name": "Black",
    "props": {
      "logo": {
        "hex": "#231f20",
        "surfaceMaterialId": "vinylSticker"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "white",
    "name": "White",
    "props": {
      "logo": {
        "hex": "#ffffff",
        "surfaceMaterialId": "vinylSticker"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "silverFoil",
    "name": "Silver Foil",
    "props": {
      "logo": {
        "hex": "#fcfcfc",
        "surfaceMaterialId": "foilSticker"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "goldFoil",
    "name": "Gold Foil",
    "props": {
      "logo": {
        "hex": "#ffe677",
        "surfaceMaterialId": "foilSticker"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "bronzeFoil",
    "name": "Bronze",
    "props": {
      "logo": {
        "hex": "#d69f5f",
        "surfaceMaterialId": "foilSticker"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "usa",
    "name": "USA",
    "props": {
      "logo": {
        "surfaceMaterialId": "vinylSticker",
        "textureId": "usa"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "vice",
    "name": "Vice",
    "props": {
      "logo": {
        "surfaceMaterialId": "vinylSticker",
        "textureId": "vice"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "blueWave",
    "name": "Blue Wave",
    "props": {
      "logo": {
        "surfaceMaterialId": "vinylSticker",
        "textureId": "blueWave"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "greenWave",
    "name": "Green Wave",
    "props": {
      "logo": {
        "surfaceMaterialId": "vinylSticker",
        "textureId": "greenWave"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "hologram",
    "name": "Hologram",
    "props": {
      "logo": {
        "surfaceMaterialId": "foilSticker",
        "textureId": "hologram"
      }
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<OvalLogoColorRow[]>> = data;

export type OvalLogoColor = typeof typed[number];

export const OVAL_LOGO_COLOR_INDEX_KEY = "id";
export type OvalLogoColorIndexKey = "id";
export type OvalLogoColorId = OvalLogoColor["id"];

let i = 0;
export const OVAL_LOGO_COLOR_DICT = {
  "black": typed[i++],
  "white": typed[i++],
  "silverFoil": typed[i++],
  "goldFoil": typed[i++],
  "bronzeFoil": typed[i++],
  "usa": typed[i++],
  "vice": typed[i++],
  "blueWave": typed[i++],
  "greenWave": typed[i++],
  "hologram": typed[i++]
} as const;

export { typed as OVAL_LOGO_COLOR };
